import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';

// components
import ConfirmedGuard from 'src/components/ballcharts/confirmedGuard';
import GoogleAd from 'src/components/ballcharts/googleads/ad';
import { useSettingsContext } from 'src/components/settings';
//
import { useTeam } from 'src/hooks/use-team';
import { HEADER, NAV } from '../config-layout';

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({ children, sx, ...other }) {
  const team = useTeam();
  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  // console.log('team.show_advertising', team.show_advertising);

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + 24}px`,
          pb: 10,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >

        <ConfirmedGuard>{children}</ConfirmedGuard>

      </Box>
    );
  }


  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        '@media print': {
          width: '100%',
          py: '10px',
        }, 

        ...sx,
      }}
      {...other}
    >
      {team && team.show_advertising === 1 && (// UPDATE THIS FOR TEAM AD (show_advertising === 2)
        <Card sx={{ display: 'flex', alignItems: 'center', m: 2}}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{width: '100%', height: 120}}
          >
            {team.show_advertising === 1 ? ( // SHOW GOOGLE ADS
              <GoogleAd slot="3278414775"/>

            ) : ( // SHOW TEAM ADS
              // coming soon.  ALSO CHANGE team.show_advertising == 1 ABOVE
              <Box />
            )}
          </Stack>
        </Card>
      )}

      <ConfirmedGuard>{children}</ConfirmedGuard>
      {team && team.show_advertising === 1 && ( // UPDATE THIS FOR TEAM AD (show_advertising === 2)
        <Card sx={{ display: 'flex', alignItems: 'center', m: 2}}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{width: '100%', height: 120}}
          >
            <GoogleAd slot="7101259659"/>
          </Stack>
        </Card>
      )}
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
